import React from 'react';
import { Heading, Flex, Text, Img } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import PreviewFeedback from '../../../components/PreviewFeedback';
import DefaultContainer from '../../../layouts/DefaultContainer';
import { declLngVariant } from '../../../utils/helpers';

const styleContainer = {
  alignItems: 'center',
};

const styleInfoContainer = {
  flexDirection: 'row',
  alignItems: 'center',
  mt: 1,
};

const styleContainerShadow = {
  borderRadius: '2xl',
  boxShadow: 'xl',
  px: 10,
  backgroundColor: 'brand.secondaryBackground',
};

const styleFeedbacksColumnContainer = {
  wrap: 'wrap',
  justifyContent: 'space-between',
  mt: [0, 0, 5],
};

const styleFeedbacksColumn1 = {
  flexDirection: 'column',
  w: ['100%', '100%', '49%', '32%'],
};

const styleFeedbacksColumn2 = {
  flexDirection: 'column',
  w: ['100%', '100%', '49%', '32%'],
  display: ['none', 'none', 'flex'],
};

const styleFeedbacksColumn3 = {
  flexDirection: 'column',
  w: ['100%', '100%', '49%', '32%'],
  display: ['none', 'none', 'none', 'flex'],
};

const styleStarIcon = {
  boxSize: 4.5,
  src: '/icons/StarBlack.svg',
  ml: 2,
  mr: 1,
};

const styleJobItemName = {
  as: 'div',
  fontSize: 'lg',
  color: 'typography.secondary',
};

const styleContainerHeader = {
  flexDirection: 'column',
};

const styleHeading = {
  as: 'h2',
  size: '2-4xl',
  mr: 1,
};

const styleHeadingContainer = {
  w: '100%',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
};

const PreviewServiceFeedbackDesktop = ({
  averageRating,
  data,
  router,
  jobItem,
  totalCount,
  ...other
}) => {
  const { t } = useTranslation(['common']);
  return (
    <DefaultContainer {...styleContainer} {...other}>
      <DefaultContainer {...styleContainerShadow}>
        <Flex {...styleContainerHeader}>
          <Flex {...styleHeadingContainer}>
            <Heading {...styleHeading}>
              {t('preview-service-feedback.feedback')}
              <Text {...styleJobItemName}>{jobItem?.name}</Text>
            </Heading>
          </Flex>
          <Flex {...styleInfoContainer}>
            <Text>
              {t('preview-service-feedback.reviews-about-masters.text', {
                count: totalCount,
                context: declLngVariant(totalCount),
              })}
            </Text>
            <Text mr={1} display='inline'>
              ,
            </Text>
            <Flex {...styleContainer}>
              <Text>{t('preview-service-feedback.average-rating')}</Text>
              <Img {...styleStarIcon} />
              <Text>{averageRating?.toFixed(1) || 0}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex {...styleFeedbacksColumnContainer}>
          <Flex {...styleFeedbacksColumn1}>
            {data?.slice(0, 3)?.map(review => (
              <PreviewFeedback key={review.id} review={review} />
            ))}
          </Flex>
          <Flex {...styleFeedbacksColumn2}>
            {data?.slice(3, 6)?.map(review => (
              <PreviewFeedback key={review.id} review={review} />
            ))}
          </Flex>
          <Flex {...styleFeedbacksColumn3}>
            {data?.slice(6, 9)?.map(review => (
              <PreviewFeedback key={review.id} review={review} />
            ))}
          </Flex>
        </Flex>
      </DefaultContainer>
    </DefaultContainer>
  );
};
export default PreviewServiceFeedbackDesktop;
